import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StarterDeckSelector } from '@clatter/ui';
import {
  requestStatus,
  setSelectedDeck,
  platformSelectors,
  setSelectedContentFiltersIds,
  useHasActiveFeatureFlag,
} from '@clatter/platform';

export const useStarterDeck = ({ selectedSlidesIds = [], requiredSlidesIds = [], featureFlag = '' }) => {
  const dispatch = useDispatch();

  const starterDecks = useSelector(platformSelectors.starterDecks.selectActiveStarterDecks);

  const allStarterDecks = useSelector(platformSelectors.starterDecks.selectAllStarterDecks);

  const selectedStarterDeck = useSelector(platformSelectors.starterDecks.selectSelectedStartedDeck);

  const starterDecksLoadingStatus = useSelector(
    ({ platform: { starterDecks } }) => starterDecks.loadingStatus === requestStatus.pending,
  );

  const showStarterDeck = useHasActiveFeatureFlag(featureFlag) && starterDecks?.length > 0;

  const showOnlyStarterSlides = useMemo(
    () =>
      selectedStarterDeck &&
      selectedStarterDeck?.hasOwnProperty('show_selected_slides_only') &&
      selectedStarterDeck?.show_selected_slides_only,
    [selectedStarterDeck?.show_selected_slides_only],
  );

  const clearSelectedStarterDeck = useCallback(() => {
    dispatch(setSelectedDeck(null));
  }, [dispatch]);

  const handleSelectDeck = useCallback(
    (deckData, onChangeCb) => {
      if (deckData?.id) {
        dispatch(setSelectedDeck(deckData));
      } else {
        clearSelectedStarterDeck();
      }
      // clear filters selections
      dispatch(setSelectedContentFiltersIds([]));
      // we need required ids to be set even if deck is cleared
      onChangeCb(deckData?.slides_ids || [], true);
    },
    [dispatch],
  );

  const renderStarterDeckSelector = useCallback(
    (onChangeCb) => {
      return (
        <StarterDeckSelector
          requiredSlidesIds={requiredSlidesIds}
          selectedSlidesIds={selectedSlidesIds}
          starterDecks={starterDecks}
          selectedDeck={selectedStarterDeck}
          onChange={(deckData) => handleSelectDeck(deckData, onChangeCb)}
        />
      );
    },
    [starterDecks, requiredSlidesIds, selectedSlidesIds],
  );

  return {
    showStarterDeck: showStarterDeck,
    allStarterDecks: allStarterDecks,
    handleSelectDeck: handleSelectDeck,
    isLoading: starterDecksLoadingStatus,
    selectedStarterDeck: selectedStarterDeck,
    showOnlyStarterSlides: showOnlyStarterSlides,
    clearSelectedStarterDeck: clearSelectedStarterDeck,
    renderStarterDeckSelector: renderStarterDeckSelector,
    selectedDeckSlidesIds: selectedStarterDeck?.slides_ids || [],
  };
};
