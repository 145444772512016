import React, { lazy, Suspense, useEffect } from 'react';
import { matchPath, Route, Routes, useLocation } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import {
  useAuth,
  fetchToolsConfig,
  fetchToolInfo,
  fetchFeatureFlag,
  fetchSystemProfile,
  sharedRoutes,
  fetchCustomAttributes,
} from '@clatter/platform';
import {
  Loader,
  Analytics,
  LogoutPageView,
  ProtectedRoute,
  SsoCallbackPage,
  AppThemeProvider,
  TrackPageviewEvent,
  appGlobalStyles,
} from '@clatter/ui';
import { useDispatch } from 'react-redux';
import routes from './routes';
import { userRolesMap } from './helpers/userRoles';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const CallbackPageView = lazy(() =>
  import('./pages/Callback/CallbackPageView'),
);
const LandingPageView = lazy(() =>
  import('./pages/LandingPage/LandingPageView'),
);
const PresentationsListView = lazy(() =>
  import('./pages/Presentations/PresentationsPageView'),
);
const SlideDetailsView = lazy(() =>
  import('./pages/DetailView/SlideDetails/SlideDetailsView'),
);
const DeckDetailsView = lazy(() =>
  import('./pages/DetailView/DeckDetails/DeckDetailsView'),
);
const PresentationDetailsView = lazy(() =>
  import('./pages/DetailView/PresentationDetails/PresentationDetailsView'),
);
const AllPresentationsReportView = lazy(() =>
  import('./pages/Reports/AllPresentationsReport/AllPresentationsReportView'),
);
const PresentationsByUserReportView = lazy(() =>
  import('./pages/Reports/PresentationsByUser/PresentationsByUserReportView'),
);
const PresentationsByUserReportGroupedView = lazy(() =>
  import('./pages/Reports/PresentationsByUserGrouped/PresentationsByUserReportGroupedView'),
);
const SlidesUsageReportView = lazy(() =>
  import('./pages/Reports/SlidesUsage/SlidesUsageReportView'),
);
const SlidesPerformanceReportView = lazy(() =>
  import('./pages/Reports/SlidePerformance/SlidePerformanceReportView'),
);
const SlidesByUserReportView = lazy(() =>
  import('./pages/Reports/SlidesByUser/SlidesByUserReportView'),
);
const SlideDetailReportView = lazy(() =>
  import('./pages/Reports/SlideDetail/SlideDetailReportView'),
);
const PresentationsCreateReportView = lazy(() =>
  import('./pages/Reports/PresentationsCreate/PresentationsCreateReportView'),
);
const PresentationsDownloadsReportView = lazy(() =>
  import('./pages/Reports/PresentationsDownloads/PresentationsDownloadsReportView'),
);
const SlidesDownloadsReportView = lazy(() =>
  import('./pages/Reports/SlidesDownloads/SlidesDownloadsReportView'),
);
const StarterDecksListView = lazy(() =>
  import('./pages/StarterDecks/StarterDecksListView'),
);
const StarterDecksReorderView = lazy(() =>
  import('./pages/StarterDecks/StarterDeckReorderView'),
);
const BuildPageView = lazy(() => import('./pages/Build/BuildEditPageView'));
const StarterDeckBuildPageView = lazy(() =>
  import('./pages/Build/StarterDeckBuildPageView'),
);
const DecksDownloadsReportView = lazy(() =>
  import('./pages/Reports/DecksDownloads/DecksDownloadsReportView'),
);
const ContentRepositoriesListView = lazy(() =>
  import('./pages/ContentRepositories/ContentRepositoriesListView'),
);
const ContentRepositoriesFormView = lazy(() =>
  import('./pages/ContentRepositories/ContentRepositoriesFormView'),
);
const IngestJobsListView = lazy(() => import('./pages/IngestJobs/IngestJobsListView'));

const GlobalStyle = createGlobalStyle`
  ${appGlobalStyles};
`;

export function App() {
  const location = useLocation();

  const dispatch = useDispatch();
  const { isAuthenticated, checkSession } = useAuth();

  const isOnLogoutPage = !!matchPath(routes.logout, location.pathname);
  const isOnCallbackPage = !!matchPath(routes.callback, location.pathname);
  const isOnSsoCallbackPage = !!matchPath(sharedRoutes.ssoCallback, location.pathname);

  useEffect(() => {
    dispatch(fetchToolInfo());
  }, [dispatch]);

  // set auth session after page refresh
  useEffect(() => {
    if (!isOnLogoutPage && !isOnCallbackPage) {
      checkSession();
    }
  }, [checkSession]);

  useEffect(() => {
    if (isAuthenticated && !isOnLogoutPage && !isOnSsoCallbackPage) {
      dispatch(fetchFeatureFlag());
      dispatch(fetchToolsConfig());
      dispatch(fetchSystemProfile());
      dispatch(fetchCustomAttributes());
    }
  }, [dispatch, isAuthenticated]);

  return (
    <AppThemeProvider>
      <GlobalStyle />
      <TrackPageviewEvent />
      <Analytics />

      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path={routes.index} element={<LandingPageView />} />
          <Route path={routes.logout} element={<LogoutPageView />} />
          <Route path={sharedRoutes.ssoCallback} element={<SsoCallbackPage />} />

          <Route
            path={routes.presentations}
            element={
              <ProtectedRoute
                element={PresentationsListView}
                accessRoles={[userRolesMap.pgUser, userRolesMap.pgSystemAdmin]}
              />
            }
          />

          <Route
            path={routes.presentationSetup}
            element={
              <ProtectedRoute element={BuildPageView} accessRoles={[userRolesMap.pgUser, userRolesMap.pgSystemAdmin]} />
            }
          />

          <Route
            path={routes.slideDetails}
            element={
              <ProtectedRoute
                element={SlideDetailsView}
                accessRoles={[userRolesMap.pgUser, userRolesMap.pgSystemAdmin]}
              />
            }
          />

          <Route
            path={routes.shareSlide}
            element={
              <ProtectedRoute
                element={SlideDetailsView}
                accessRoles={[userRolesMap.pgUser, userRolesMap.pgSystemAdmin]}
              />
            }
          />

          <Route
            path={routes.deckDetails}
            element={
              <ProtectedRoute
                element={DeckDetailsView}
                accessRoles={[userRolesMap.pgUser, userRolesMap.pgSystemAdmin]}
              />
            }
          />

          <Route
            path={routes.shareDeck}
            element={
              <ProtectedRoute
                element={DeckDetailsView}
                accessRoles={[userRolesMap.pgUser, userRolesMap.pgSystemAdmin]}
              />
            }
          />

          <Route
            path={routes.presentationDetails}
            element={
              <ProtectedRoute
                element={PresentationDetailsView}
                accessRoles={[userRolesMap.pgUser, userRolesMap.pgSystemAdmin]}
              />
            }
          />

          <Route path={routes.callback} element={<CallbackPageView />} />
          <Route path={routes.reportAllPresentations} element={<AllPresentationsReportView />} />
          <Route path={routes.reportUserPresentations} element={<PresentationsByUserReportView />} />

          <Route
            path={routes.reportUserPresentationsSpike}
            element={
              <ProtectedRoute
                element={PresentationsByUserReportGroupedView}
                accessRoles={[userRolesMap.pgUser, userRolesMap.pgSystemAdmin]}
              />
            }
          />

          <Route
            path={routes.starterDecks}
            element={<ProtectedRoute element={StarterDecksListView} accessRoles={[userRolesMap.pgSystemAdmin]} />}
          />

          <Route
            path={routes.starterDeckSetup}
            element={<ProtectedRoute element={StarterDeckBuildPageView} accessRoles={[userRolesMap.pgSystemAdmin]} />}
          />

          <Route
            path={routes.starterDeckReorder}
            element={<ProtectedRoute element={StarterDecksReorderView} accessRoles={[userRolesMap.pgSystemAdmin]} />}
          />

          <Route
            path={routes.reportPresentationsCreate}
            element={
              <ProtectedRoute element={PresentationsCreateReportView} accessRoles={[userRolesMap.pgSystemAdmin]} />
            }
          />

          <Route
            path={routes.reportPresentationsDownloads}
            element={
              <ProtectedRoute element={PresentationsDownloadsReportView} accessRoles={[userRolesMap.pgSystemAdmin]} />
            }
          />

          <Route
            path={routes.reportSlidesDownloads}
            element={<ProtectedRoute element={SlidesDownloadsReportView} accessRoles={[userRolesMap.pgSystemAdmin]} />}
          />

          <Route
            path={routes.reportDecksDownloads}
            element={<ProtectedRoute element={DecksDownloadsReportView} accessRoles={[userRolesMap.pgSystemAdmin]} />}
          />

          <Route path={routes.reportSlidesUsage} element={<SlidesUsageReportView />} />
          <Route path={routes.reportSlideDownloadCounts} element={<SlidesPerformanceReportView />} />
          <Route path={routes.reportSlidesByUser} element={<SlidesByUserReportView />} />
          <Route path={routes.reportSlideDetail} element={<SlideDetailReportView />} />

          <Route
            path={routes.ingestJobs}
            element={<ProtectedRoute element={IngestJobsListView} accessRoles={[userRolesMap.pgSystemAdmin]} />}
          />

          <Route
            path={routes.contentRepositories}
            element={
              <ProtectedRoute element={ContentRepositoriesListView} accessRoles={[userRolesMap.pgSystemAdmin]} />
            }
          />

          <Route
            path={routes.addContentRepository}
            element={
              <ProtectedRoute element={ContentRepositoriesFormView} accessRoles={[userRolesMap.pgSystemAdmin]} />
            }
          />

          <Route
            path={routes.editContentRepository}
            element={
              <ProtectedRoute element={ContentRepositoriesFormView} accessRoles={[userRolesMap.pgSystemAdmin]} />
            }
          />
        </Routes>
      </Suspense>
    </AppThemeProvider>
  );
}

export default App;
