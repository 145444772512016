import { configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import {
  PLATFORM_FEATURE_KEY,
  globalErrorHandlerMiddleware,
  platformReducers,
} from '@clatter/platform';
import { SORT_TAB_FEATURE_KEY, sortTabReducer } from './sort-tab.slice';
import { USERS_FEATURE_KEY, usersReducer } from "./users.slice";
import { INGEST_JOBS_FEATURE_KEY, ingestJobsReducer } from "./ingestJobs.slice";

export {
  selectAllIngestJobs,
  selectIngestJobsLoadingStatus,
  selectIngestJobsFiltersOptions,
} from './ingestJobs.slice';
export {
  clearAddedSlidesIds,
  setAddedSlidesIds,
} from './sort-tab.slice'

export const storeConfig = {
  reducer: {
    [PLATFORM_FEATURE_KEY]: platformReducers,
    [SORT_TAB_FEATURE_KEY]: sortTabReducer,
    [USERS_FEATURE_KEY]: usersReducer,
    [INGEST_JOBS_FEATURE_KEY]: ingestJobsReducer,
  },
  // Additional middleware can be passed to this array
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(globalErrorHandlerMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
};

export const store = configureStore(storeConfig);
